function toggleModal(modal) {
  modal.classList.toggle('modal--open');
  document.getElementById('first-modal-link').focus();
}

document.addEventListener("turbolinks:load", function() {
  var btn = document.querySelectorAll('.video__play,.modal__open');

  for (var i = 0; i < btn.length; i++) {
    var thisBtn = btn[i];

    thisBtn.addEventListener("click", function(){
      var modal = document.getElementById(this.dataset.modal);

      toggleModal(modal);
      event.preventDefault();

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function(event) {
        if (event.target == modal) {
          toggleModal(modal);
        }
      }
    }, false);
  }

  // Simple method for capturing modal focus.
  // Requires you to add 'first-modal-link' and 'last-modal-link' classes in

  document.getElementById('last-modal-link').addEventListener("keydown", function(e){
    if (e.which == 9 && (e.shiftKey == false) ) { //keycode for TAB and NOT HOLDING SHIFT
      e.preventDefault();
      document.getElementById('first-modal-link').focus();
    }
  });

  document.getElementById('first-modal-link').addEventListener("keydown", function(e){
    if (e.which == 9 && e.shiftKey ) { //keycode for Shift + TAB
      e.preventDefault();
      document.getElementById('last-modal-link').focus();
    }
  });
});