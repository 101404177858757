document.addEventListener("turbolinks:load", function() {
  // IE11 polyfill
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }

  // remove hide class for control
  var filterClass = document.getElementsByClassName('examples__filter');
  
  if (filterClass.length == 1) {
    filterClass[0].classList.remove('hide');
  
    var filterElements = document.querySelectorAll('.filter-div');
    var salaryFilterElement = document.querySelector('select[name="salary"]');
    var serviceFilterElement = document.querySelector('select[name="joined"]');
  
    var salaryThreshold = parseInt(salaryFilterElement.dataset.salaryThreshold);
    var joinedThreshold = new Date(serviceFilterElement.dataset.joinedThreshold);
    var yearsOfServiceThreshold = (new Date().getTime() - joinedThreshold.getTime()) / (1000 * 60 * 60 * 24 * 365); // years
  
    if (salaryThreshold != null) {
      document.querySelectorAll('select.member-filter').forEach(function (select) {
        select.addEventListener('change', function () {
          var selectedSalaryFilter = salaryFilterElement.options[salaryFilterElement.selectedIndex].value;
          var selectedServiceFilter = serviceFilterElement.options[serviceFilterElement.selectedIndex].value;
    
          filterElements.forEach(function (element) {
            var exampleSalary = parseInt(element.dataset.salary);
            var exampleService = parseInt(element.dataset.service);
    
            var metSalaryThreshold = selectedSalaryFilter === 'under' && exampleSalary < salaryThreshold
              || selectedSalaryFilter === 'over' && exampleSalary >= salaryThreshold
              || selectedSalaryFilter === 'all';
    
            var metServiceThreshold = selectedServiceFilter === 'before' && exampleService >= yearsOfServiceThreshold
              || selectedServiceFilter === 'after' && exampleService < yearsOfServiceThreshold
              || selectedServiceFilter === 'all';
    
            // length of service and salary thresholds must both be met for filter element to be visible
            if (!metSalaryThreshold || !metServiceThreshold) {
              element.classList.add('hide');
            } else {
              element.classList.remove('hide');
            }
          });
        });
      });    
    }
  }
});
