var sessionTimeout;

function onTimeout() {
  if (!document.location.toString().includes('logged_out')) {
    document.location = '/home/logged_out';
  }
};

function setTimer() {
  clearTimeout(sessionTimeout);
  sessionTimeout = setTimeout(onTimeout, 30 * 60 * 1000);
};

setTimer();
addEventListener("mousemove", (event) => { setTimer() });
addEventListener("keydown", (event) => { setTimer() });
