// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
require('cookies');
require('filter');
require('modal');
require('accordion');
require('timeout');

document.addEventListener("turbolinks:load", function() {
  // banner close button
  var closebtns = document.getElementsByClassName("close-link");
  var i;

  for (i = 0; i < closebtns.length; i++) {
    closebtns[i].addEventListener("click", function() {
      this.parentNode.parentNode.classList.add('hide');
      event.preventDefault();
    });
  }

  // show and hide submit question
  var submitToggle = document.getElementById("submit__toggle")
  var submitContent = document.getElementById("submit__qs")
  if (submitToggle != null) {
    submitToggle.addEventListener("click", function() {
      if (submitContent.classList) {
        submitContent.classList.toggle('show');
      }
      document.getElementById('submit__notice').style.display = 'none';
      event.preventDefault();
    });
  }

  // faq collapsibles
  var coll = document.getElementsByClassName("collapsible");
  var j;

  for (j = 0; j < coll.length; j++) {
    coll[j].addEventListener("click", function() {
      this.classList.toggle("active");
      var content = this.nextElementSibling;
      if (content.style.maxHeight){
        content.style.maxHeight = null;
      } else {
        content.style.maxHeight = content.scrollHeight + "px";
      }
    });
  }
})
