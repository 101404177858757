function setCookie() {
  var d = new Date();
  d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days
  var expires = "expires=" + d.toUTCString();
  document.cookie = "accepted-cookies=true;" + expires + ";path=/";
}

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
  else return null;
}

function closeDiv() {
  var closeCookies = document.getElementById("accept-cookies");
  if (closeCookies) {
    closeCookies.onclick = function () {
      var element = document.getElementById("cookies");
      element.parentNode.removeChild(element);
      setCookie();
    };
  }
}

function createDiv() {
  var bodytag = document.getElementsByTagName("body")[0];
  var div = document.createElement("div");
  div.setAttribute("id", "cookies");
  div.setAttribute("class", "cookies__banner");
  div.innerHTML =
    "<p>We use cookies to provide you with a better online experience and for statistics and measurements purposes. You can accept or reject cookies which aren’t essential for the site to work. Find out more in our  <a href='https://staging.unileverbenefitchoices.com/home/cookie_policy'>Cookie Policy</a>. <button id='accept-cookies' class='cookies__close'>I accept</button></p>";

  bodytag.insertBefore(div, bodytag.firstChild); // Adds the Cookie Law Banner just after the opening <body> tag
}

document.addEventListener("turbolinks:load", function () {
  var myCookie = getCookie("accepted-cookies");

  if (myCookie == null) {
    createDiv();
  }

  closeDiv();
});
